import React, { useEffect, useState } from 'react';
import { TranslationService } from '@rocheglobal/component-library-dot-com/src/services/translation/index';
import { StringService as StringHelper } from '@rocheglobal/component-library-dot-com/src/services/string/index';
import { DateService } from '@rocheglobal/component-library-dot-com/src/services/date/index';
import { getComponent } from '..';
import { ExternalDataService } from '../../services/external-data';
import { ExternalDataProps } from '../../templates/types';
import { SEO } from '../custom/seo';
import { LanguageService, StringService } from '../../services';
import { FontOverride } from '../custom/font-override';
import { DateServiceTemp } from '../../services/date-time-formatter';

const mockedBreadcrumbs = [
  {
    name: 'Media',
    real_path: '/media',
    slug: '/media',
  },
  {
    name: 'Releases',
    real_path: '/media/releases',
    slug: '/media/releases',
  },
  {
    name: 'Detail',
    real_path: '/detail',
    slug: '/detail',
  },
];

/**
 * Do not use `LanguageService.getLocaleAwareLink` as currently localised
 * versions of these pages do not exist
 */
const mockedLinks = [
  {
    text: 'Contact us',
    url: '/contact/media-contacts',
  },
  {
    text: 'Subscribe to Roche news',
    url: '/media/subscribe',
  },
  {
    text: 'Ad hoc announcements',
    url: '/ad-hoc-announcement',
  },
];

const Breadcrumbs = getComponent('roche-breadcrumbs') as React.ElementType;

const getTranslations = async (): Promise<Record<string, unknown>> => {
  const translations = {
    translations: {
      backToBranchHeadline: await TranslationService.translate('media releases'),
      backToBranchLinkText: await TranslationService.translate('view all'),
      backToBranchOverlineText: await TranslationService.translate('media'),
      download: await TranslationService.translate('downloads'),
      stayUpdatedHeadline: await TranslationService.translate('stay updated'),
      stayUpdatedCopy: await TranslationService.translate('get the latest news and updates to your inbox.'),
      subscribe: await TranslationService.translate('subscribe'),
      services: await TranslationService.translate('services'),
      // See the documentation in the LanguageService
      readThisArticleIn: LanguageService.readThisArticleIn,
    },
  };
  return translations;
};

export const MediaReleasesDetail = (props: ExternalDataProps) => {
  const { locale, params: { slug } } = props;
  const [state, setState] = useState(undefined);

  useEffect(() => {
    ExternalDataService.get(`${ExternalDataService.endpoint.news}/${StringService.removeFileExtension(slug)}`)
      .then(async (result: Record<string, unknown>) => {
        ExternalDataService.handle404(result);
        const ReleaseDateTime = DateServiceTemp.convertDateToTimeZone(result.ReleaseDateTime as string | Date, 'Europe/Berlin');
        return setState({
          ...(result as Record<string, unknown>),
          ...await getTranslations(),
          parsedDate: await DateService.formatDate(ReleaseDateTime, 'dateFull'),
          convertedDate: ReleaseDateTime,
        });
      });
  }, []);

  if (!state) {
    return null;
  }

  const downloads = (ExternalDataService.getLocalisedValue(state, 'Attachments', locale) as Record<string, string>[]) || [];
  const availableLanguages = Object.keys(state.Content || {})
    .filter((language) => language !== locale);
  return (
    <>
      <FontOverride overrides={props.pageContext.fontOverwrites} />
      <SEO
        contentType='pages'
        authorized_roles={[]}
        lang={locale}
        slug={LanguageService.getLocaleAwareLink(`/media/releases/${slug}`, locale)}
        teaser_title={ExternalDataService.getLocalisedValue(state, 'Title', locale) as string}
        description={ExternalDataService.getLocalisedValue(state, 'ContentSummary', locale) as string}
        og_title={ExternalDataService.getLocalisedValue(state, 'Title', locale) as string}
        releaseType='media'
        releaseDate={state.convertedDate}
      />
      <Breadcrumbs list={mockedBreadcrumbs} />

      <roche-grid>
        <roche-staging-hero
          variant="hero--variant-b"
          headline={ExternalDataService.getLocalisedValue(state, 'Title', locale) as string}
        />
      </roche-grid>

      {!!availableLanguages.length
        && <>
          <roche-grid layout="3-3-3-3">
            {availableLanguages.map((language, index) => (
              <roche-text-link
                slot={['left', 'center-left', 'center-right', 'right'][index % 4]}
                key={language}
                href={LanguageService.getLocaleAwareLink(props.location.pathname, language)}
                text={state.translations?.readThisArticleIn?.[language]}
                left-icon="globe"
                target="_self"
              >
              </roche-text-link>
            ))}
          </roche-grid>
          <roche-magnet></roche-magnet>
        </>
      }

      <roche-grid layout="8-1-3">
        <roche-richtext slot="left">
          <roche-overline
            overline-text={state.parsedDate}
          />
        </roche-richtext>

        <roche-richtext
          slot="left"
          dangerouslySetInnerHTML={{
            __html: `
              ${ExternalDataService.getContentStyleOverwrite()}
              ${ExternalDataService.getLocalisedValue(state, 'Content', locale)}
          `,
          }}
        />

        <roche-headline
          slot="right"
          level={5}
          headline={StringHelper.capitalize(state.translations?.services)}
        />

        {
          mockedLinks
            .map((item, index) => (
              <div slot="right" key={index}>
                <roche-generic-link
                  text={item.text}
                  url={item.url}
                  icon="chevron-right"
                  icon-position="before"
                  size="medium"
                />
              </div>
            ))
        }

        {!!downloads.length && <roche-headline
          slot="right"
          level={5}
          headline={StringHelper.capitalize(state.translations?.download)}
        />
        }

        {
          downloads
            .map((item, index) => (
              <roche-teaser
                is-download-link
                slot="right"
                key={index}
                teaser-title={item.Title}
                teaser-url={item.Url}
                teaser-description={StringService.getExtension(item.Url || '').toUpperCase()}
              />
            ))
        }

      </roche-grid>

      <roche-grid layout="8-1-3">
        <roche-back-to-branch
          slot="left"
          /**
           * Do not use `LanguageService.getLocaleAwareLink` as currently
           * localised versions of '/media/releases' do not exist
           */
          href='/media/releases'
          headline={StringHelper.capitalize(state.translations?.backToBranchHeadline)}
          level={5}
          link-text={StringHelper.capitalize(state.translations?.backToBranchLinkText)}
          overline-text={StringHelper.capitalize(state.translations?.backToBranchOverlineText)}
          icon="list"
        />
      </roche-grid>

      <roche-grid layout="8-4" color="blue-gradient-top-left">
        <roche-headline
          slot="left"
          level={2}
          headline={StringHelper.capitalize(state.translations?.stayUpdatedHeadline)}
        />

        <roche-richtext slot="left">
          <p>{StringHelper.capitalize(state.translations?.stayUpdatedCopy)}</p>
        </roche-richtext>

        <roche-button
          slot="left"
          text={StringHelper.capitalize(state.translations?.subscribe)}
          appearance="secondary"
          /**
           * Do not use `LanguageService.getLocaleAwareLink` as currently
           * localised versions of '/media/subscribe' do not exist
           */
          href='/media/subscribe'
        />
      </roche-grid>
    </>
  );
};
