/* eslint-disable filenames/match-regex */
import React from 'react';
import { MediaReleasesDetail } from '../../../components/view/media-releases-detail';
import { ExternalData } from '../../../templates/external-data';
import { ExternalDataProps } from '../../../templates/types';

const MediaReleasesDetailPage = (props: ExternalDataProps) => <ExternalData>
  <MediaReleasesDetail {...props} />
</ExternalData>;

// eslint-disable-next-line import/no-default-export
export default MediaReleasesDetailPage;
